<template>
    <div class="">
        <div class="hloga" @click="goto('/',null)" ><!--<img :src="hloga">--></div>
        <section>
            <div>
                    <video-player  class="video-player vjs-custom-skin"
                                   id="playerOptions1"
                                   x-webkit-airplay="true"
                                   :playsinline="true"
                                   :options="playerOptions1"
                                   :events="playerEvents" >
                    </video-player>

            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;color: #202020" >
                    <div><span class="btLine">辉腾</span>使用</div>
                </div>
                <div class="hcell" style="width: 420px;font-size: 24px;font-weight: bold;color: #202020;" >
                    铸平台共创价值   用科技改变生活
                </div>
                <div class="hcell" style="width: 980px;" >
                    <div class="htable" style="justify-content: space-between;text-align: left">
                        <div class="hcell" style="width: 320px; ">
                            <div style="height: 156px;overflow: hidden;"><img :src="qywh14" ></div>
                            <div class="htable" style="margin-top: 7px;background-color: #f3f3f3;height: 156px;overflow: hidden;  font-size: 16px;line-height:28px;color: #202020;padding-left: 24px;align-items: center;">
                                <div class="hcell">
                                依靠科技进步，立足太阳能创新应用，<br/>
                                致力于改变人们生活方式，<br/>
                                提高乐享绿色环保生存质量。
                                </div>
                            </div>
                            <div style="margin-top: 7px" ><img :src="qywh15" ></div>
                        </div>
                        <div class="hcell" style="width: 320px; ">
                            <div class="htable" style="background-color: #f3f3f3;height: 156px;overflow: hidden;font-size: 16px;line-height:28px;color: #202020;padding-left: 30px;align-items: center;">
                                <div class="hcell">
                                铸造志同道合的经营、发展的事业平台，<br/>
                                形成互为主体、资源共通、价值共创、  <br/>
                                收益共享的开放型合作的利益共同体。
                                </div>
                            </div>
                            <div style="margin-top: 7px"><img :src="qywh13" ></div>
                        </div>
                        <div class="hcell" style="width: 156px; "><img :src="qywh12" ></div>
                        <div class="hcell" style="width: 156px; "><img :src="qywh11" ></div>
                    </div>
                </div>
                <div class="hcell" style="width: 130px;" ></div>
            </div>
        </section>

        <section style="margin-top: 90px;" class="qywhbj">
            <div class="body1660 htable" style="height:100%;color: #ffffff;">
                <div class="hcell" style="width: 130px;" >
                    <div style="margin-top: 90px;"><span class="btLine">辉腾</span>价值观</div>
                </div>
                <div class="hcell" style="width: 1400px;height:100%;" >
                    <div class="htable" style="height:100%;text-align: left;" >
                        <div class="hcell" style="width: 350px;height:100%;border-right:1px solid rgba(255,255,255,0.8);padding: 0px 68px 0px 68px;" >
                            <div style="font-size: 24px;font-weight: bold;margin-top: 310px;">{{senseofworth[0].tb_title}}</div>
                            <div style="font-size: 16px;margin-top: 32px;line-height: 24px;">
                                {{senseofworth[0].tb_summary}}
                            </div>
                        </div>
                        <div class="hcell" style="width: 350px;height:100%;border-right:1px solid rgba(255,255,255,0.8);padding: 0px 68px 0px 68px;" >
                            <div style="font-size: 24px;font-weight: bold;margin-top: 310px;">{{senseofworth[1].tb_title}}</div>
                            <div style="font-size: 16px;margin-top: 32px;line-height: 24px;">
                                {{senseofworth[1].tb_summary}}
                            </div>
                        </div>
                        <div class="hcell" style="width: 350px;height:100%;border-right:1px solid rgba(255,255,255,0.8);padding: 0px 68px 0px 68px;" >
                            <div style="font-size: 24px;font-weight: bold;margin-top: 310px;">{{senseofworth[2].tb_title}}</div>
                            <div style="font-size: 16px;margin-top: 32px;line-height: 24px;">
                                {{senseofworth[2].tb_summary}}
                            </div>
                        </div>
                        <div class="hcell" style="width: 350px;height:100%;padding: 0px 68px 0px 68px;" >
                            <div style="font-size: 24px;font-weight: bold;margin-top: 310px;">{{senseofworth[3].tb_title}}</div>
                            <div style="font-size: 16px;margin-top: 32px;line-height: 24px;">
                                {{senseofworth[3].tb_summary}}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 130px;" ></div>
            </div>
        </section>
        <section style="margin-top: 90px;" >
            <div class="body1660 htable"  >
                <div class="hcell" style="width: 130px;" >
                    <div ><span class="btLine">文化</span>建设</div>
                </div>
                <div class="hcell" style="width: 1400px;">
                    <div>
                        <div class="htable" style="background-color: #f3f3f3; ">
                            <div class="hcell"><img :src="qywhjt1" style="display: flex"></div>
                            <div class="hcell" style="width: 800px;">

                                <div class="htable" style="padding: 0 60px;align-items: center;height: 330px;text-align: left;">
                                    <div class="hcell">
                                        <div style="font-size: 24px;font-weight: bold;">辉腾能源党支部</div>
                                        <div style="font-size: 16px;line-height: 24px;margin-top: 30px;">
                                            重庆辉腾能源股份有限公司成立于2005年，位于留学生创业园B2-7，是西南地区首家专业从事低日照地区太阳能光伏系统设计研发及生产销售为一体的双高科技型企业。
                                            公司党支部成立于2017年4月，共有6名正式党员。支部委员会设置支部书记1名，支部副书记1名，组织委员1名。
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="htable" style="background-color: #f3f3f3; margin-top: 40px;">
                            <div class="hcell"  style="width: 800px;">
                                <div class="htable" style="padding: 0 60px;align-items: center;height: 330px;text-align: left;">
                                    <div class="hcell">
                                    <div style="font-size: 24px;font-weight: bold;">辉腾能源工会</div>
                                    <div style="font-size: 16px;line-height: 24px;margin-top: 30px;">
                                        公司秉承创新是第一推进力理念，坚持经营理念、关键技术、产品研发、运营管理和商业模式持续创新升级。邀请专家、学者作专业顾问，从前沿市场、从发达城市的新思维、新理念、新模式上协助公司优化管理模式，招揽优秀人才，升级管理平台。积极拓展市场领域，加速推动移动电源、移动电磁炉等新产品落地应用。
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hcell"><img :src="qywhjt2" style="display: flex"></div>
                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 130px;" ></div>
            </div>
        </section>
        <section style="margin-top: 80px;"></section>

    </div>
</template>
<script>
    import 'video.js/dist/video-js.css'
    import {videoPlayer} from 'vue-video-player'
    import {listTopicBases } from "../hts/api.js"

    export default {
        name: "",

        components: {
            videoPlayer,
        },
        data() {
            return {
                param: {
                    pageindex: 1,
                },

                hloga: require('../assets/hloga.png'),
                qywh11: require('../assets/qywh11.png'),
                qywh12: require('../assets/qywh12.png'),
                qywh13: require('../assets/qywh13.png'),
                qywh14: require('../assets/qywh14.png'),
                qywh15: require('../assets/qywh15.png'),
                qywhjt1:require('../assets/qywhjt1.png'),
                qywhjt2:require('../assets/qywhjt2.png'),
                playerOptions1: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.8, 1.0, 1.5, 2.0],
                    aspectRatio: '192:73',
                    loop: false,
                    sources: [{
                        type: "video/mp4",
                        src: require('../assets/hiten2.mp4')
                    }],
                    poster: require('../assets/aboutv2.png'),
                },
                senseofworth:[]
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            goto(url,query) {
                this.$router.push({ path: url, query: query });
            },
            getList () {
                // console.log(this.id)
                listTopicBases({type:'newWebsite:[senseofworth]', page: 1, rows: 6 })
                    .then(resData => {
                        this.senseofworth=resData.data.senseofworth
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>
    .hloga{
        position: absolute;
        z-index: 11111;
        background-image: url('~assets/hloga.png');
        width: 230px;
        height: 30px;
        top: 65px;
        left: 130px;
    }
    .hloga:hover {
        cursor: pointer;
        background-color: rgba(255,255,255,0.5);
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }
    .qywhbj{
        background-image: url('~assets/qywhbj.png');
        height: 700px;
    }

</style>
